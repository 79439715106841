<template>
  <div>
    <b-container class="mt-5 mb-5">
      <h1 class="fs-4 font-weight-bold mb-0">Betalingspartners</h1>
      <p class="text-muted">Om te kunnen starten met de verkoop dien je nog een betalingspartner te koppelen. Nadat je deze hebt aangesloten kun je direct starten met de verkoop.</p>

      <b-card class="mb-4">
        <div class="mb-3">
          <label>Betalingspartner</label>
          <p class="text-muted">Door een betalingspartner te koppelen is het mogelijk om betalingen te verwerken. Transactiekosten worden automatisch verrekend.</p>
          <b-form-select v-model="form.payment_provider" :options="providersOptions" />
        </div>
        <hr/>

        <template v-if="form.payment_provider == 'mollie'">
          <div class="mb-3">
            <b-row>
              <b-col md="6">
                <label>Huidige status</label>
              </b-col>
              <b-col md="6">
                <div class="fs-5 mb-2">
                  <template v-if="form.payment_provider_status == 0"><span class="text-danger">Niet verbonden</span></template>
                  <template v-else><span class="text-success">Verbonden</span></template>
                </div>
                <b-button v-if="form.payment_provider_status == 0" @click="startConnection('mollie')" variant="primary">Connect via Mollie</b-button>
              </b-col>
            </b-row>
          </div>
          <div class="mb-3">
            <b-row>
              <b-col md="6">
                <label>Testmode actief</label>
              </b-col>
              <b-col md="6">
                <div class="mb-3">
                  <b-form-select v-model="form.is_payment_provider_testmode" :options="yesNoOptions" />
                </div>
              </b-col>
            </b-row>
          </div>
        </template>
      </b-card>
      <div class="clearfix">
        <b-button @click="saveSettings" variant="primary" class="float-right">
          Opslaan
          <font-awesome-icon icon="fa-solid fa-chevron-right" />
        </b-button>
      </div>
    </b-container>
  </div>
</template>

<script>
  import { mapActions } from 'pinia'
  import { useShopStore } from '@/stores/shop'
    
  export default {
    metaInfo: {
        title: 'Betalingspartners',
    },
    components: {
    },
    data() {
        return {
          form: {
            payment_provider: null,
            payment_provider_status: null,
            is_payment_provider_testmode: null,
          },
          providersOptions: [
            { text: 'Selecteer een optie', value: null },
            { text: 'Mollie B.V.', value: 'mollie' }
          ],
          yesNoOptions: [
            { value: false, text: 'Nee' },
            { value: true, text: 'Ja' },
          ]
        }
    },
    computed: {},
    methods: {
      ...mapActions(useShopStore, ['setLoading']),
      getOrganisation: function() {
        this.setLoading(true);
        this.$axios.get("https://api.tixgo.nl/organisations/show")
          .then( response => {
            this.form = response.data;
            this.setLoading(false);
          })
        ;
      },
      startConnection: function(provider) {
        let access_token = localStorage.getItem('token');
        window.location = 'https://api.tixgo.nl/' + provider + '/connect?access_token=' + access_token;
      },
      saveSettings: function() {
        this.$axios.put("https://api.tixgo.nl/organisations/update", this.form)
          .then( response => {
              response;
            this.$router.push('/manage/organisation')
          })
        ;
      }
    },
    created() {
      this.getOrganisation();
    }
  }
</script>